.headerbar {
  background-color: #01476b;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between
};
.header-img {
  height: 80%;
  width: 80%;
}